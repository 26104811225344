import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePaginationActions from '../components/TablePaginationActions';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { useUsers } from '../api';
import { Button } from '@mui/material';

const UsersPage: React.FC = () => {
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useUsers({
    offset,
    limit: usersPerPage,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <Box maxWidth="md" m={'auto'} pt={2}>
      <Button variant="outlined" component={RouterLink} to="/users/create">
        Criar Usuário
      </Button>

      <TableContainer component={Paper} sx={{ margin: '1rem auto 0 auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((user) => (
              <TableRow
                key={user.id}
                onClick={() => navigate(`/users/${user.id}`)}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                    opacity: 0.75,
                  },
                }}
              >
                <TableCell>
                  {dayjs(user.createdAt).format('DD/MM/YYYY - HH:mm:ss')}
                </TableCell>
                <TableCell>
                  {[user.firstName, user.lastName].join(' ').trim() ||
                    'Anônimo'}
                </TableCell>
                <TableCell>{user.email ?? '-'}</TableCell>
                <TableCell>{user.role ?? '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={3}
                count={20}
                rowsPerPage={usersPerPage}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={(_, page) => {
                  setPage(page);
                  setOffset(page * usersPerPage);
                }}
                onRowsPerPageChange={({ target: { value } }) =>
                  setUsersPerPage(+value)
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersPage;
