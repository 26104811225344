import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import MainAppBar from './components/MainAppBar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './utils/auth';
import MainDrawer from './components/MainDrawer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { DRAWER_WIDTH, APPBAR_HEIGHT } from './utils/constants';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <CssBaseline />
          <MainDrawer />
          <MainAppBar />

          <Box
            sx={{
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
              ml: `${DRAWER_WIDTH}px`,
              mt: `${APPBAR_HEIGHT}px`,
            }}
          >
            <Container component="main">
              <AppRoutes />
            </Container>
          </Box>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
