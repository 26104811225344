import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, useLogin } from '../utils/auth';
import { DRAWER_WIDTH } from '../utils/constants';

const Topbar: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { logout } = useLogin();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exit = () => {
    logout();
    handleClose();
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <AppBar
      sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, ml: `${DRAWER_WIDTH}px` }}
      position="fixed"
    >
      <Toolbar variant="dense">
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          <Typography variant="h6" color="inherit" component="div">
            LogPoney
          </Typography>
        </Link>

        <Box sx={{ marginLeft: 'auto' }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Icon>account_circle</Icon>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={exit}>Sair</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
