import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/auth';
import { DRAWER_WIDTH } from '../utils/constants';

const MainDrawer: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar variant="dense" />
      <Divider />
      <List>
        <ListItem button onClick={() => navigate('/sessions')}>
          <ListItemIcon>
            <Icon>preview</Icon>
          </ListItemIcon>
          <ListItemText primary="Sessões" />
        </ListItem>
        <ListItem button onClick={() => navigate('/users')}>
          <ListItemIcon>
            <Icon>people</Icon>
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default MainDrawer;
