import React, { useEffect, useRef } from 'react';
import rrwebPlayer from 'rrweb-player';
import { eventWithTime } from 'rrweb/typings/types';

type PlayerProps = {
  events: eventWithTime[];
};

const Player: React.FC<PlayerProps> = ({ events }) => {
  const playerElRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<rrwebPlayer | null>(null);

  useEffect(() => {
    if (playerRef.current) {
      // directly destroy the svelte component
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (playerRef.current as any).$destroy();
      playerRef.current = null;
    }

    if (events) {
      playerElRef.current?.childNodes.forEach((node) => node.remove());

      const replayer = new rrwebPlayer({
        target: playerElRef.current!,
        props: {
          events,
        },
      });
      replayer.play();

      playerRef.current = replayer;
    }

    return () => {
      if (playerRef.current) {
        // directly destroy the svelte component
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (playerRef.current as any).$destroy();
      }
    };
  }, [events]);

  return <div ref={playerElRef} id="rrweb-player" />;
};

export default Player;
