import React from 'react';
import { useParams } from 'react-router-dom';
import { useSession } from '../api';
import Player from '../components/Player';

const SessionPage: React.FC = () => {
  const { sessionID } = useParams();
  const { data, isLoading } = useSession(sessionID!);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const events = data?.map((event) => event.event);

  if (!events) {
    return <div>No events</div>;
  }

  return <Player events={events} />;
};

export default SessionPage;
