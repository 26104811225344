import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from './utils/auth';

import LoginPage from './pages/Login';
import NotFoundPage from './pages/NotFound';
import SessionPage from './pages/Session';
import SessionsPage from './pages/Sessions';
import UsersPage from './pages/Users';
import UserPage from './pages/User';
import CreateUserPage from './pages/CreateUser';

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/sessions" />} />
      <Route path="/sessions" element={<SessionsPage />} />
      <Route path="/sessions/:sessionID" element={<SessionPage />} />

      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/create" element={<CreateUserPage />} />
      <Route path="/users/:userID" element={<UserPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
