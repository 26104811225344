import api from './api';
import { Events_Response } from '../types/events';
import { Event_Session_Response } from '../types/events-session';
import { useQuery } from 'react-query';
import { PaginationType } from '../types/utils';

async function getSessionEvents(sessionID: string): Promise<Events_Response> {
  const res = await api.get(`/events?sessionId=${sessionID}&limit=99999999`);
  return res.data;
}

export function useSession(sessionID: string) {
  return useQuery(
    ['session', sessionID],
    async () => await getSessionEvents(sessionID),
  );
}

type Event_Session_Response_With_Count = {
  count: number;
  sessions: Event_Session_Response;
};

async function getSessions({
  offset,
  limit,
}: PaginationType): Promise<Event_Session_Response_With_Count> {
  const res = await api.get<Event_Session_Response>(
    `/events/session?offset=${offset}&limit=${limit}`,
  );

  console.log('res.headers', res.headers);

  // headers are missing because of cors server configuration
  const count = +(res.headers['x-total-count'] ?? 100);

  return { sessions: res.data, count };
}

export function useSessions({
  offset = 0,
  limit = 20,
}: Partial<PaginationType> = {}) {
  const res = useQuery<Event_Session_Response_With_Count>(
    ['sessions', offset, limit],
    async () =>
      await getSessions({
        offset,
        limit,
      }),
    { keepPreviousData: true },
  );

  return res;
}
