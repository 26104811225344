import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useSessions } from '../api';
import TablePaginationActions from '../components/TablePaginationActions';

const SessionsPage: React.FC = () => {
  const [sessionsPerPage, setSessionsPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const theme = useTheme();

  const { data } = useSessions({
    limit: sessionsPerPage,
    offset,
  });
  const navigate = useNavigate();

  if (!data?.sessions) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ py: theme.spacing(2), px: theme.spacing(1) }}>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: 800, margin: '1rem auto 0 auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.sessions.map((session) => (
              <TableRow
                key={session.id}
                onClick={() => navigate(`/sessions/${session.id}`)}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                    opacity: 0.75,
                  },
                }}
              >
                <TableCell>
                  {dayjs(session.createdAt).format('DD/MM/YYYY - HH:mm:ss')}
                </TableCell>
                <TableCell>
                  {[session.firstName, session.lastName].join(' ').trim() ||
                    'Anônimo'}
                </TableCell>
                <TableCell>{session.email ?? '-'}</TableCell>
                <TableCell>{session.role ?? '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPageOptions={[10, 25, 50]}
                colSpan={3}
                count={data.count}
                rowsPerPage={sessionsPerPage}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={(_, page) => {
                  setPage(page);
                  setOffset(page * sessionsPerPage);
                }}
                onRowsPerPageChange={({ target: { value } }) =>
                  setSessionsPerPage(+value)
                }
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SessionsPage;
