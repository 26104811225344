import React from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { PostUserBody, PostUserResponse, UserRole } from '../types/users';
import { postUser } from '../api/users';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

type CreateUserFormType = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: UserRole;
};

const requiredFieldText = 'Preenchimento obrigatório';

const getMuiErrorProps = (error?: FieldError) => ({
  error: !!error,
  helperText: error && (error.message || requiredFieldText),
});

const CreateUserPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<CreateUserFormType>({
    mode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation<
    PostUserResponse,
    unknown,
    PostUserBody
  >((user) => postUser(user));

  const onSubmit = async () => {
    const values = getValues();
    mutate({
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role as UserRole,
    });
  };

  return (
    <Box sx={{ pt: '16px' }}>
      <Typography variant="h4" gutterBottom>
        Criar Poney
      </Typography>

      <Grid
        component="form"
        container
        spacing={2}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <TextField
            label="Email"
            fullWidth
            variant="outlined"
            type="email"
            required
            {...register('email', { required: true })}
            {...getMuiErrorProps(errors?.email)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Senha"
            fullWidth
            variant="outlined"
            type="password"
            {...register('password', { required: true })}
            {...getMuiErrorProps(errors?.password)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Primeiro nome"
            fullWidth
            variant="outlined"
            {...register('firstName', { required: true })}
            {...getMuiErrorProps(errors?.firstName)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Último nome"
            fullWidth
            variant="outlined"
            {...register('lastName', { required: true })}
            {...getMuiErrorProps(errors?.lastName)}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
              {...register('role', { required: true })}
              error={!!errors?.role}
            >
              <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
              <MenuItem value={UserRole.DEVELOPER}>Desenvolvedor</MenuItem>
              <MenuItem value={UserRole.INTERNAL}>Interno</MenuItem>
              <MenuItem value={UserRole.VIEWER}>Visualizador</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={isLoading} variant="contained" type="submit">
            Criar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateUserPage;
