export interface User {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
}

export const enum UserRole {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  VIEWER = 'viewer',
  INTERNAL = 'internal',
}

export type GetUsersResponse = User[];

export type PostUserBody = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  role: UserRole;
};

export type PostUserResponse = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};
