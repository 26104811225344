import React, { createContext, useState, useContext } from 'react';
import { useMutation } from 'react-query';
import api from '../api/api';

type AuthContextType = {
  state: AuthStateType;
  setState: React.Dispatch<React.SetStateAction<AuthStateType>>;
};

type AuthStateType = {
  user: unknown | null;
  token: string | null;
};

const initialValue: AuthStateType = {
  user: null,
  token: null,
};

function getInitialState(): AuthStateType {
  const rawState = localStorage.getItem('auth');

  if (rawState === null) {
    return initialValue;
  }

  const state = JSON.parse(rawState);
  return state;
}

const AuthContext = createContext<AuthContextType>(null as any);

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthStateType>(getInitialState);

  return (
    <AuthContext.Provider value={{ state: auth, setState: setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { state } = useContext(AuthContext);
  if (!state) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return {
    isAuthenticated: !!state.user,
    user: state.user,
    token: state.token,
  };
};

export const useLogin = () => {
  const { state, setState } = useContext(AuthContext);
  if (!state) {
    throw new Error('useLogin must be used within a AuthProvider');
  }

  const { mutate, ...rest } = useMutation<
    boolean,
    unknown,
    { email: string; password: string }
  >('login', async ({ email, password }) => {
    try {
      const {
        data: { access_token, ...user },
      } = await api.post('/auth/login', { email, password });

      const newState = {
        user,
        token: access_token,
      };

      setState(newState);
      setAuth(newState);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  });

  return {
    login: mutate,
    logout: () => {
      setState(initialValue);
      cleanAuth();
    },
    ...rest,
  };
};

export const getAuthToken = () => {
  const auth = localStorage.getItem('auth');

  return auth ? JSON.parse(auth).token : null;
};

export const setAuth = (auth: AuthStateType) => {
  localStorage.setItem('auth', JSON.stringify(auth));
};

export const cleanAuth = () => {
  localStorage.removeItem('auth');
};
