import {
  User,
  GetUsersResponse,
  PostUserBody,
  PostUserResponse,
} from '../types/users';
import api from './api';
import { useQuery } from 'react-query';

import { PaginationType } from '../types/utils';

const getUsers = async ({
  offset,
  limit,
}: PaginationType): Promise<GetUsersResponse> => {
  const res = await api.get(`/users?offset=${offset}&limit=${limit}`);
  return res.data;
};

export function useUsers({ offset = 0, limit = 20 }: PaginationType) {
  return useQuery<User[]>(['users', offset, limit], () =>
    getUsers({ offset, limit }),
  );
}

export const postUser = async (
  user: PostUserBody,
): Promise<PostUserResponse> => {
  const res = await api.post('/users', user);
  return res.data;
};
